export const filterMenu = (filterOptions, { menu }) => {
  const { categories } = menu;
  const { searchString, allergens } = filterOptions;
  let filteredMenu = categories;
  console.log({ searchString });
  if (searchString) {
    filteredMenu = searchFilter(filteredMenu, searchString);
  }

  if (allergens) {
    filteredMenu = allergensFilters(filteredMenu, allergens);
  }
  console.log({ filteredMenu, searchString });
  return { ...menu, categories: filteredMenu };
};

const allergensFilters = (menu, allergens) => {
  let hideItemsWith = [];
  let showOnlyItemsWith = [];
  for (const allergen of allergens) {
    console.log({ a: allergen?.showOnlyItemsWith });
    if (allergen.hideItemsWith) {
      const hide = allergen.hideItemsWith.map((item) =>
        String(item).toLocaleLowerCase()
      );
      hideItemsWith.push(hide);
    }
    if (allergen.showOnlyItemsWith) {
      const show = allergen.showOnlyItemsWith.map((item) =>
        String(item).toLocaleLowerCase()
      );

      showOnlyItemsWith.push(show);
    }
  }

  hideItemsWith = hideItemsWith.flat();
  console.log({ showOnlyItemsWith });
  showOnlyItemsWith = showOnlyItemsWith.flat();
  console.log({ showOnlyItemsWith, hideItemsWith });
  if (showOnlyItemsWith.length) {
    return showOnlyItemsWithFilter(menu, showOnlyItemsWith);
  }
  if (hideItemsWith.length) {
    return hideItemWithFilter(menu, hideItemsWith);
  }

  return menu;
};

const hideItemWithFilter = (menu, hideItemsWith) => {
  const filteredMenu = [];
  for (const category of menu) {
    const filteredSubCategory = [];
    for (const subCategory of category?.categories) {
      const filteredItems = [];
      for (const item of subCategory?.items) {
        const attributes = item.transformedAttributes || [];
        const { allergensKeys = [] } = attributes;
        const found = hideItemsWith.find((item) =>
          allergensKeys.includes(item)
        );

        if (!found) {
          filteredItems.push(item);
        }
      }
      if (filteredItems.length) {
        filteredSubCategory.push({ ...subCategory, items: filteredItems });
      }
    }
    if (filteredSubCategory.length) {
      filteredMenu.push({ ...category, categories: filteredSubCategory });
    }
  }
  return filteredMenu;
};

const showOnlyItemsWithFilter = (menu, showOnlyItemsWith) => {
  const filteredMenu = [];
  for (const category of menu) {
    const filteredSubCategory = [];
    for (const subCategory of category?.categories) {
      const filteredItems = [];
      for (const item of subCategory?.items) {
        const attributes = item.transformedAttributes || [];

        const { allergensKeys = [] } = attributes;

        const found = showOnlyItemsWith.find((item) =>
          allergensKeys.includes(item)
        );
        console.log({ showOnlyItemsWith, allergensKeys, found });

        if (found) {
          filteredItems.push(item);
        }
      }
      if (filteredItems.length) {
        filteredSubCategory.push({ ...subCategory, items: filteredItems });
      }
    }
    if (filteredSubCategory.length) {
      filteredMenu.push({ ...category, categories: filteredSubCategory });
    }
  }
  return filteredMenu;
};

const searchFilter = (categories, searchString) => {
  const searchStringCasing = searchString.toLowerCase();
  const filteredMenu = [];
  for (const category of categories) {
    const filteredSubCategory = [];
    for (const subCategory of category?.categories) {
      const filteredItems = [];
      for (const item of subCategory?.items) {
        const name = String(item?.name).toLowerCase();
        if (
          name.includes(searchStringCasing) ||
          name.toLowerCase().includes(searchStringCasing)
        ) {
          filteredItems.push(item);
        }
      }
      if (filteredItems.length) {
        filteredSubCategory.push({ ...subCategory, items: filteredItems });
      }
    }
    if (filteredSubCategory.length) {
      filteredMenu.push({ ...category, categories: filteredSubCategory });
    }
  }
  return filteredMenu;
};
