import * as yup from "yup";

export const generatePayload = ({
  outlet,
  tipsAmount,
  comment,
  locationId,
  items,
  orderTypeId,
  totalPaid,
}) => {
  let tipsPayload = {};
  if (tipsAmount && tipsAmount > 0) {
    tipsPayload = {
      itemId: -1,
      quantity: 1,
      unitPrice: tipsAmount,
    };
  }

  const { outletId } = outlet;
  const itemsPayload = generateItemsPayload(items);

  return {
    outletId,
    orderTypeId,
    comments: comment,
    locationId,
    totalPaid: 23,
    items: [...itemsPayload, tipsPayload],
  };
};

const generateItemsPayload = (items) => {
  console.log(items);
  return Object.values(items).map((item) => {
    return {
      itemId: item.itemId,
      quantity: item.quantity,
      comments: item.comment,
      items: generateModifierPayload(item?.selectedModifiers),
    };
  });
};

const generateModifierPayload = (modifiers = []) => {
  return modifiers.map((modifier) => ({
    itemId: modifier.itemId,
    quantity: 1,
  }));
};

export const getInitialValues = ({ orderFor, orderType, name = "" }) => {
  const initialValues = { lastName: name, roomNumber: null };

  if (orderType == 3) {
    initialValues.roomNumber = orderFor?.identifier;
  }

  if (!Boolean(initialValues.roomNumber) && orderFor?.identifier) {
    initialValues.roomNumber = orderFor?.identifier;
  }

  return initialValues;
};

export const roomChargesSchema = yup.object({
  lastName: yup.string().required().nullable().label("Last name"),
  roomNumber: yup.string().required().nullable().label("Room number"),
});

export const getRoomChargePayload = ({ provider, commonPayloads }) => {
  switch (provider) {
    case "TigerTMS Room Charge":
      return {
        TigerTMSPaymentModel: { ...commonPayloads },
        providerName: "TigerTMS Room Charge",
      };
    case "StayntouchTms":
      return {
        StayntouchTmsPaymentModel: { ...commonPayloads },
        providerName: "stayntouchtms",
      };
    default:
      return {};
  }
};
