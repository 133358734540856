import camel from "to-camel-case";

const transformCheckoutFields = (value) => {
  const values = String(value).split("|");
  const valuesObject = values.map((item) => {
    const [label, isRequired, type] = String(item).split(";");
    return {
      name: camel(label),
      label: `${label} ${isRequired !== "1" ? "(optional)" : ""}`,
      isRequired: isRequired === "1",
      type,
    };
  });
  return valuesObject;
};

export const parseJson = (val) => {
  try {
    return JSON.parse(val);
  } catch (e) {
    console.error(e);
    return [];
  }
};

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const createVariablesNames = (value) => {
  const [firstName, ...rest] = String(value).split(" ");
  const a = firstName.toLowerCase();
  const restNames = rest.map(capitalizeFirstLetter);
  return [a, ...restNames].join("");
};

export const transformOutletAttributes = (attributes) => {
  const newAttributes = {
    isEmailRequired: false,
    isNameRequired: false,
    getEmail: false,
    getName: false,
    hideTipping: false,
    allowSurchargeRemoval: false,
    unavailableMessage: false,
    checkoutFields: [],
    maxItemCommentChars: 10000,
    maxOrderCommentChars: 10000,
    allergenFilters: [],
    isDigitalMenu: false,
    squareEdges: false,
    tipAmounts: [10, 15, 20],
    checkoutMsgBody: "",
    checkoutMsgHeading: "",
    checkoutMessageIsEnabled: false,
  };

  for (const { key, value } of attributes) {
    if (key === "showEmail" && value === "1") {
      newAttributes.getEmail = true;
    }
    if (key === "showName" && value === "1") {
      newAttributes.getName = true;
    }
    if (key === "mandatoryName" && value === "1") {
      newAttributes.isNameRequired = true;
    }
    if (key === "mandatoryEmail" && value === "1") {
      newAttributes.isEmailRequired = true;
    }
    if (key === "hideTipping" && value === "1") {
      newAttributes.hideTipping = true;
    }
    if (key === "allowSurchargeRemoval" && value === "1") {
      newAttributes.allowSurchargeRemoval = true;
    }
    if (key === "isActive" && value === "1") {
      newAttributes.isActive = true;
    }

    if (key === "isAvailable") {
      newAttributes.isAvailable = Boolean(value === "1" || value === "true");
    }

    if (key === "unavailableMessage") {
      newAttributes.unavailableMessage = Boolean(value) ? value : false;
    }
    if (key === "availableTime" && Boolean(value)) {
      newAttributes.availableTime = value;
    }
    if (key === "collectionMessage" && Boolean(value)) {
      newAttributes.collectionMessage = value;
    }
    if (key === "confirmationMessage" && Boolean(value)) {
      newAttributes.confirmationMessage = value;
    }
    if (key === "additionalInformation" && Boolean(value)) {
      newAttributes.additionalInformation = value;
    }
    if (key === "checkoutFields") {
      newAttributes.checkoutFields = transformCheckoutFields(value);
    }
    if (key === "maxItemCommentChars") {
      newAttributes.maxItemCommentChars = value;
    }
    if (key === "maxOrderCommentChars") {
      newAttributes.maxOrderCommentChars = value;
    }

    if (key === "isDigitalMenu") {
      newAttributes.isDigitalMenu = Boolean(value);
    }

    if (key === "allergenFilters") {
      newAttributes.allergenFilters = parseJson(value).map((item) => ({
        ...item,
        label: item.name,
        key: createVariablesNames(item.name),
      }));
    }

    if (key === "squareEdges:Boolean" && value === "1") {
      newAttributes.squareEdges = true;
    }

    if (key === "tipAmounts") {
      newAttributes.tipAmounts = value?.split(",").map(Number);
    }
    if (key === "checkoutMsgBody") {
      newAttributes.checkoutMsgBody = value;
    }
    if (key === "checkoutMsgHeading") {
      newAttributes.checkoutMsgHeading = value;
    }
  }

  if (newAttributes?.checkoutMsgBody && newAttributes?.checkoutMsgHeading) {
    newAttributes.checkoutMessageIsEnabled = true;
  }

  return newAttributes;
};
